var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Árazás típus"}},[_c('validation-provider',{attrs:{"name":"árazás típus","rules":"required","vid":"pricingType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.pricingTypeOptions},model:{value:(_vm.formData.pricingType),callback:function ($$v) {_vm.$set(_vm.formData, "pricingType", $$v)},expression:"formData.pricingType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kezdés napja","description":"HK: munkanap, HV: nem munkanap"}},[_c('validation-provider',{attrs:{"name":"kezdés napja","rules":"required","vid":"startDayType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.startDayTypeOptions},model:{value:(_vm.formData.startDayType),callback:function ($$v) {_vm.$set(_vm.formData, "startDayType", $$v)},expression:"formData.startDayType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Min. napok száma"}},[_c('validation-provider',{attrs:{"name":"min. napok száma","rules":"required","vid":"minNumberOfDays"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.minNumberOfDays),callback:function ($$v) {_vm.$set(_vm.formData, "minNumberOfDays", $$v)},expression:"formData.minNumberOfDays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Max. napok száma"}},[_c('validation-provider',{attrs:{"name":"max. napok száma","vid":"maxNumberOfDays"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.maxNumberOfDays),callback:function ($$v) {_vm.$set(_vm.formData, "maxNumberOfDays", $$v)},expression:"formData.maxNumberOfDays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Min. résztvevők száma"}},[_c('validation-provider',{attrs:{"name":"min. résztvevők száma","rules":"required","vid":"minNumberOfParticipants"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.minNumberOfParticipants),callback:function ($$v) {_vm.$set(_vm.formData, "minNumberOfParticipants", $$v)},expression:"formData.minNumberOfParticipants"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Max. résztvevők száma"}},[_c('validation-provider',{attrs:{"name":"max. résztvevők száma","vid":"maxNumberOfParticipants"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.maxNumberOfParticipants),callback:function ($$v) {_vm.$set(_vm.formData, "maxNumberOfParticipants", $$v)},expression:"formData.maxNumberOfParticipants"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Érvényesség kezdete"}},[_c('validation-provider',{attrs:{"name":"érvényesség kezdete","rules":"required","vid":"validFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.validFrom),callback:function ($$v) {_vm.$set(_vm.formData, "validFrom", $$v)},expression:"formData.validFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Bruttó ár"}},[_c('validation-provider',{attrs:{"name":"bruttó ár","rules":"required","vid":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }