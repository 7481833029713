<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>

          <b-col md="6">
            <b-form-group
                label="Árazás típus"
            >
              <validation-provider
                  #default="{ errors }"
                  name="árazás típus"
                  rules="required"
                  vid="pricingType"
              >
                <v-select
                    v-model="formData.pricingType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="pricingTypeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Kezdés napja"
                description="HK: munkanap, HV: nem munkanap"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kezdés napja"
                  rules="required"
                  vid="startDayType"
              >
                <v-select
                    v-model="formData.startDayType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="startDayTypeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Min. napok száma"
            >
              <validation-provider
                  #default="{ errors }"
                  name="min. napok száma"
                  rules="required"
                  vid="minNumberOfDays"
              >
                <b-form-input
                    v-model="formData.minNumberOfDays"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Max. napok száma"
            >
              <validation-provider
                  #default="{ errors }"
                  name="max. napok száma"
                  vid="maxNumberOfDays"
              >
                <b-form-input
                    v-model="formData.maxNumberOfDays"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Min. résztvevők száma"
            >
              <validation-provider
                  #default="{ errors }"
                  name="min. résztvevők száma"
                  rules="required"
                  vid="minNumberOfParticipants"
              >
                <b-form-input
                    v-model="formData.minNumberOfParticipants"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Max. résztvevők száma"
            >
              <validation-provider
                  #default="{ errors }"
                  name="max. résztvevők száma"
                  vid="maxNumberOfParticipants"
              >
                <b-form-input
                    v-model="formData.maxNumberOfParticipants"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Érvényesség kezdete"
            >
              <validation-provider
                  #default="{ errors }"
                  name="érvényesség kezdete"
                  rules="required"
                  vid="validFrom"
              >
                <flat-pickr
                    v-model="formData.validFrom"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Bruttó ár"
            >
              <validation-provider
                  #default="{ errors }"
                  name="bruttó ár"
                  rules="required"
                  vid="price"
              >
                <b-form-input
                    v-model="formData.price"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    cancelAndGOTOPreviousPage,
    flatPickr
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        pricingType: '',
        startDayType: '',
        minNumberOfDays: '',
        maxNumberOfDays: '',
        minNumberOfParticipants: '',
        maxNumberOfParticipants: '',
        validFrom: '',
        price: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      pricingTypeOptions: [],
      startDayTypeOptions: [],
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchPricingTypesForSelect').then(response => {
      this.pricingTypeOptions = response
      this.formData.pricingType = this.pricingTypeOptions[0]
    })
    this.$store.dispatch('reservationReservationPricingBaseVariableFeeStartDayTypes').then(response => {
      this.startDayTypeOptions = response.data.values
      this.formData.startDayType = this.startDayTypeOptions[0]
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.pricingType = this.formData.pricingType.value
          fd.startDayType = this.formData.startDayType.value

          this.$store.dispatch('storeReservationPricingBaseVariableFee', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-pricing-base-variable-fees'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else if(error.response.status === 500){
              this.$helpers.showErrorToast(error.response.data.message)
              if(typeof error.response.data.data.id !== "undefined"){
                if(this.$acl.canEditPermission(this.role))
                      this.$router.push({name: 'modify-reservation-pricing-base-variable-fee', params: {id: error.response.data.data.id}})
              }
            }else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
